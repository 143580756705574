@import url(https://use.typekit.net/eoq7tal.css);
/* @import url("https://fonts.googleapis.com/css2?family=Concert+One&family=Montserrat:wght@400;600&family=Open+Sans:wght@400;600&display=swap"); */
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: ff-zwo-web-pro, sans-serif !important; */
  /* font-family: "Open Sans", sans-serif !important; */
  font-family: brandon-grotesque, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#base {
  min-height: 100vh;
}

::selection {
  background: #ff7c7c81;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(5vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.custom.react-toggle .react-toggle-track .react-toggle-track-check,
.custom.react-toggle .react-toggle-track .react-toggle-track-x {
  height: 15px;
}

.custom.react-toggle .react-toggle-track {
  background-color: rgb(154, 201, 255);
}

.custom.react-toggle .react-toggle-track:hover,
.custom.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: rgb(136, 191, 255);
}

.custom.react-toggle--checked .react-toggle-track {
  background-color: rgb(255, 151, 91);
}
.custom.react-toggle--checked .react-toggle-track:hover,
.custom.react-toggle--checked:hover:not(.react-toggle--disabled)
  .react-toggle-track {
  background-color: rgb(255, 137, 68);
}

.custom.react-toggle .react-toggle-thumb {
  border: none;
}

.custom.react-toggle--focus .react-toggle-thumb,
.custom.react-toggle--active .react-toggle-thumb {
  box-shadow: none;
}

.custom.react-toggle {
  margin: auto 0.5rem;
  width: 50px;
}

