/* @import url("https://fonts.googleapis.com/css2?family=Concert+One&family=Montserrat:wght@400;600&family=Open+Sans:wght@400;600&display=swap"); */
@import url("https://use.typekit.net/eoq7tal.css");
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: ff-zwo-web-pro, sans-serif !important; */
  /* font-family: "Open Sans", sans-serif !important; */
  font-family: brandon-grotesque, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#base {
  min-height: 100vh;
}

::selection {
  background: #ff7c7c81;
}
